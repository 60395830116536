module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@6.20.0_si33cbqs2tr4ff6z335qn22vpm/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"withWebp":true,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@5.20.0_5q5viszt6ot7ezb5wwsl4ao2hq/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@4.20.0_5q5viszt6ot7ezb5wwsl4ao2hq/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-116900824-2"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.20.0_qxfvqn5ydvzvumhc7us2mywliy/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Blog by hung.dev","short_name":"Blog by hung.dev","theme_color":"hsl(31, 92%, 62%)","background_color":"hsl(0, 0%, 100%)","icon":"content/photo.jpg","display":"standalone","start_url":"/","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a84fa1c019c2bb80d82577166d9386f6"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@5.20.0_5q5viszt6ot7ezb5wwsl4ao2hq/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"}]}},
    },{
      plugin: require('../node_modules/.pnpm/@sentry+gatsby@7.9.0_gatsby@4.20.0+react@18.2.0/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"tracesSampleRate":1},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@4.20.0_gatsby@4.20.0/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.20.0_o7p2eledc5ffqhhczpzxxj36ke/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
